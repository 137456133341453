import React from 'react';
import Link from '../../components/common/link';

function InsuranceLink() {
  return (
    <div className="InsuranceLink">
      <Link href="/insurance-list">
        Übersicht der im Vergleich teilnehmenden und nicht teilnehmenden Versicherer
      </Link>
    </div>
  );
}

export default InsuranceLink;
