import { isClient } from 'bu.helpers';
import storeFactory from '../factories/store.factory';

class SearchDataUrlSyncService {

  setupPushToRouterReactionDone = false;

  setupPushToRouterReaction() {
    if (this.setupPushToRouterReactionDone) {
      return;
    }
    if (isClient()) {
      const searchDataStore = storeFactory.getSearchDataStore();

      searchDataStore.createQueryStringSyncReaction();

      this.setupPushToRouterReactionDone = true;
    }
  }

}

export default new SearchDataUrlSyncService();
