/* eslint-disable */

import { SEARCH_DATA_FIELDS } from "bu.lookups"

export default {
  [SEARCH_DATA_FIELDS.JOB_SITUATION]: {
    name: SEARCH_DATA_FIELDS.JOB_SITUATION,
    content: <div>
      Ihre berufliche Situation hat Einfluss auf die Tarifwahl: <br />
      <b>Angestellt</b>: Sie sind angestellt  <br />
      <b>Selbständig</b>: Sie sind selbständig oder freiberuflich tätig  <br />
      <b>Beamtet</b>: Sie sind Beamter oder Beamtenanwärter  <br />
      <b>Studierend</b>: Sie befinden sich aktuell im Studium  <br />
      <b>In Ausbildung</b>: Sie machen gerade eine Ausbildung  <br />
      <b>Andere (z.B. Schüler)</b>: Sie sind in der Schule oder führen einen Haushalt
    </div>
  },
  [SEARCH_DATA_FIELDS.OCCUPATION]: {
    name: SEARCH_DATA_FIELDS.OCCUPATION,
    content: <div>Die Tarifberechnung hängt wesentlich vom Risiko Ihres Berufes ab. <br />
      Bitte geben Sie die ersten Buchstaben Ihres aktuell ausgeübten Berufs oder Ihrer aktuell
      ausgeübten Tätigkeit ein und treffen dann eine möglichst genaue Auswahl aus den vorgeschlagenen Berufen</div>
  },
  [SEARCH_DATA_FIELDS.EDUCATION_TYPE]: {
    name: SEARCH_DATA_FIELDS.EDUCATION_TYPE,
    content: <div>Ihr Bildungsabschluss hat Einfluss auf die Tarifberechnung</div>
  },
  [SEARCH_DATA_FIELDS.BIRTHDAY]: {
    name: SEARCH_DATA_FIELDS.BIRTHDAY,
    content: <div>Ihr Geburtsdatum hat Einfluss auf die Tarifberechnung</div>
  },
  [SEARCH_DATA_FIELDS.FRACTION_OFFICE_WORK]: {
    name: SEARCH_DATA_FIELDS.FRACTION_OFFICE_WORK,
    content: <div>Körperlich arbeitende Menschen (z.B. Handwerker) haben ein erhöhtes Berufsunfähigkeitsrisiko. Deshalb
      ist es für die Tarifberechnung wichtig, den durchschnittlichen Anteil an nicht-körperlicher Arbeit
      (z.B. Schreibtischtätigkeit) bei Ihrer Arbeit anzugeben</div>
  },
  [SEARCH_DATA_FIELDS.STAFF_RESPONSIBILITY]: {
    name: SEARCH_DATA_FIELDS.STAFF_RESPONSIBILITY,
    content: <div>Einige Versicherer erheben geringere Beiträge, wenn Sie Personalverantwortung haben.
      Bitte geben Sie an, wie vielen Personen Sie (ca.) disziplinarisch vorgesetzt sind</div>
  },
  [SEARCH_DATA_FIELDS.INDUSTRY]: {
    name: SEARCH_DATA_FIELDS.INDUSTRY,
    content: <div>Für Beschäftigte bestimmter Branchen gibt es spezielle Sondertarife, zum Beispiel die <b>KlinikRente</b>, <b>MetallRente</b> und <b>IG Bergbau Chemie Energie</b> und <b>Presse-Versorgung</b>.<br />Die Sondertarife gelten auch für Auszubildende und Studenten sowie Partner und Kinder der Beschäftigten</div>
  },
  [SEARCH_DATA_FIELDS.FAMILY_STATUS]: {
    name: SEARCH_DATA_FIELDS.FAMILY_STATUS,
    content: <div>Einige Versicherer bieten Rabatte, wenn Sie mit Ihrem Partner und mindestens einem minderjährigen Kind zusammenleben. Andere Versicherer bieten besondere Leistungen, wenn Sie Kinder haben</div>
  },
  [SEARCH_DATA_FIELDS.SMOKER]: {
    name: SEARCH_DATA_FIELDS.SMOKER,
    contentOld: `Einige Versicherer bieten Rabatte für Nichtraucher. Bitte wählen Sie "Ja", wenn Sie in den letzten 12 Monaten (gelegentlich) geraucht haben`,
    content: <div>Einige Versicherer bieten Rabatte für Nichtraucher. Wählen Sie "Ja", wenn Sie in den letzten 12 Monaten (gelegentlich) geraucht haben. Als Nichtraucher geben Sie bitte an, wie lange Sie nicht mehr rauchen</div>
  },
  [SEARCH_DATA_FIELDS.BENEFIT_AMOUNT]: {
    name: SEARCH_DATA_FIELDS.BENEFIT_AMOUNT,
    content: <div>Die Verbraucherzentrale empfiehlt eine monatliche Berufsunfähigkeitsrente in Höhe von ca. 75% Ihres Nettoeinkommens.<br/>Wenn Sie derzeit <b>kein</b> Einkommen haben, z.B. weil Sie studieren, empfiehlt sich eine Absicherung von ca. <b>1.500 €</b></div>
  },
  [SEARCH_DATA_FIELDS.BENEFIT_AGE_LIMIT]: {
    name: SEARCH_DATA_FIELDS.BENEFIT_AGE_LIMIT,
    content: <div>Das Endalter bestimmt den Zeitraum bis zu dem Ihnen die Versicherung Leistungen auszahlt. Um sich für Ihr gesamtes Erwerbsleben abzusichern empfehlen die Experten der Stiftung Warentest daher eine Absicherung bis zum Renteneintritt (mit 67 Jahren)</div>
  },
  [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_JOB_SITUATION]: {
    name: SEARCH_DATA_FIELDS.PUBLIC_SERVANT_JOB_SITUATION,
    content: <div>Die Art des Beamtenverhältnisses hat Einfluss auf die Tarifberechnung</div>
  },
  [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_CAREER_GROUP]: {
    name: SEARCH_DATA_FIELDS.PUBLIC_SERVANT_CAREER_GROUP,
    content: <div>Die Laufbahngruppe hat Einfluss auf die Tarifberechnung</div>
  },
  [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_EXPERIENCE_YEARS]: {
    name: SEARCH_DATA_FIELDS.PUBLIC_SERVANT_EXPERIENCE_YEARS,
    content: <div>Hier ist nur die Anzahl der Erfahrungsjahre relevant, die Ihnen beim Diensteintritt anerkannt wurden. Alle seit dem Diensteintritt erworbenen Erfahrungsjahre werden automatisch berechnet</div>
  },
  [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE]: {
    name: SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE,
    content: <div>Das Datum des Diensteintritts hat Einfluss auf die Tarifberechnung</div>
  },
  [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE]: {
    name: SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE,
    content: <div>Das Datum der Ernennung zum Beamten auf Lebenszeit hat Einfluss auf die Tarifberechnung. Als Beamter auf Probe geben Sie bitte das erwartete Datum der Ernennung ein. Für Beamte auf Widerruf wird das Datum pauschal auf sechs Jahre nach dem Diensteintritt festgelegt.</div>
  },
  [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_PAY_GRADE]: {
    content: <div>Die Besoldungsgruppe hat Einfluss auf die Tarifberechnung</div>
  },

};
