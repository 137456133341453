import React from 'react';

import Button from '../common/button/button';

type Props = {
  onClick: () => void;
  dataTestId: string;
  label: string;
};

function OnboardingCta(props: Props) {
  return (
    <div className="OnboardingCta">
      <Button className="OnboardingCta-button"
        dataTestId={props.dataTestId}
        hasNavigationSpinner
        onClick={() => props.onClick()}>
        {props.label}
      </Button>
    </div>
  );
}

export default OnboardingCta;
