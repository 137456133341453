import React from 'react';
import classnames from 'classnames';

import CONSULTANT_CHECKPOINTS from './../../constants/consultant-checkpoints.constant';
import CheckSvg from '../../svgs/check.svg';
import PhoneSvg from '../../svgs/phone-transparent.svg';
import ConsultantCarousel from './../../components/consultant-carousel/consultant-carousel';
import storeFactory from '../../factories/store.factory';

type Props = {
  useConsultantImage?: boolean;
  isComparison?: boolean;
}
class ConsultantInformationCarousel extends React.Component<Props> {
  consultantCarouselStore = storeFactory.getConsultantCarouselStore();

  static defaultProps = {
    useConsultantImage: false,
    isComparison: false
  };

  componentDidMount() {
    this.consultantCarouselStore.loadConsultants();
  }

  _renderCheckpoint(checkpoint: (typeof CONSULTANT_CHECKPOINTS)[number]) {
    return (
      <div className="ConsultantCheckpoints-checkpointWrapper" key={String(checkpoint)}>
        <div className="ConsultantCarouselCheckpoints-checkpointIconContainer">
          <CheckSvg className="ConsultantCarouselCheckpoints-checkpointIcon"/>
        </div>
        <div className="ConsultantCarouselCheckpoints-checkpointText">
          {checkpoint}
        </div>
      </div>
    );
  }

  _renderServiceHotline() {
    return (
      <div className="ConsultantCheckpoints-serviceHotline">
        <a className="ConsultantCheckpoints-phoneNumberWrapper" href="tel:08924241266">
          <PhoneSvg className="ConsultantCheckpoints-phoneSvg" />
          <div className="ConsultantCheckpoints-phonenNumber">089 - 24 24 12 66</div>
        </a>
      </div>
    );
  }

  _renderConsultantInfo() {
    return (
      <div className="ConsultantCheckpoints-consultantInfoWrapper">
        <ConsultantCarousel/>
      </div>
    );
  }

  render() {
    const wrapperClassName = classnames('ConsultantCheckpoints-wrapper',
      { 'is-comparison': this.props.isComparison });

    return (
      <div className={wrapperClassName}>
        <div className="ConsultantCheckpoints-headLine">
          Ihre persönliche Beratung bei CHECK24
        </div>
        {!this.props.useConsultantImage && this._renderServiceHotline()}
        {this.props.useConsultantImage && this._renderConsultantInfo()}
        {CONSULTANT_CHECKPOINTS.map((checkpoint) =>
          this._renderCheckpoint(checkpoint))}
      </div>
    );
  }
}

export default ConsultantInformationCarousel;
