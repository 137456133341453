import React, { ReactNode } from 'react';
import NextLink from 'next/link';
import type { UrlObject } from 'url';

type Props = {
  href: string|UrlObject;
  children: ReactNode;
  prefetch?: boolean;
  target?: string;
  className?: string;
  rel?: string;
  replace?: boolean;
};

function Link({
  href,
  children,
  ...props
}: Props) {
  return <NextLink {...props} href={href} >{ children }</NextLink>;
}

export default Link;
