import { shouldShowCampaignBanner, getLastDayOfDeadline, calculateCampaignDuration } from 'bu.helpers';
import configService from './config.service';

class EndOfYearBannerService {
  showEndOfYearBanner() {
    const { startDate, deadlines } = configService.getConfig().endOfYearCampaign;
    return shouldShowCampaignBanner(startDate, deadlines);
  }

  calculateCampaignDuration() {
    const { deadlines } = configService.getConfig().endOfYearCampaign;
    return calculateCampaignDuration(deadlines);
  }

  getLastDayOfDeadline() {
    const { deadlines } = configService.getConfig().endOfYearCampaign;
    return getLastDayOfDeadline(deadlines);
  }
}

export default new EndOfYearBannerService();
