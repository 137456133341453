import React from 'react';
import { observer } from 'mobx-react';
import { PRODUCT } from 'bu.lookups';
import { Mobile } from 'bu.components-customer-apps';
import { formatNameToSalute } from 'bu.helpers';

import storeFactory from '../../factories/store.factory';
import piwikService from '../../services/piwik.service';

type Props = {
  isOnResultPage?: boolean;
}
@observer
class ConsultantCarouselWrapper extends React.Component<Props> {

  consultantCarouselStore = storeFactory.getConsultantCarouselStore();
  tariffListStore = storeFactory.getTariffListStore();
  ssoStore = storeFactory.getSsoStore();

  static defaultProps = {
    isOnResultPage: false
  };

  render() {
    return (
      <Mobile.ConsultantCarousel
        product={PRODUCT.BU}
        onResultPage={this.props.isOnResultPage!}
        customerSalutation={formatNameToSalute(this.ssoStore.ssoUserData)}
        consultants={[...this.consultantCarouselStore.consultants]}
        isInComparisonMode={this.tariffListStore.isInComparisonMode}
        onClickRight={() => piwikService.trackCurrentPageEvent('RightConsultant_Clicked')}
        onClickLeft={() => piwikService.trackCurrentPageEvent('LeftConsultant_Clicked')}
      />
    );
  }
}

export default ConsultantCarouselWrapper;
