import React from 'react';
import { Mobile } from 'bu.components-customer-apps';

export type CampaignForBanner = {
  campaignTitleShort?: string;
  isDeadline?: boolean;
  campaignComment?: string;
  resultZeroComment?: string;
  campaignCommentHeadline?: string;
}

type CampaignBannerProps = {
  campaign: CampaignForBanner|undefined;
  openCampaignComment?: boolean;
  isComparison?: boolean;
};

export default function CampaignBanner({
  campaign, openCampaignComment = true, isComparison = false
}: CampaignBannerProps) {

  const text = (openCampaignComment ? campaign?.campaignComment : campaign?.resultZeroComment) ?? '';

  return campaign?.campaignTitleShort ? (

    <Mobile.Banner
        modalHeader={<div className="CampaignBanner-headline">{campaign.campaignCommentHeadline}</div>}
        className={isComparison ? 'is-small' : ''}
        modalContent={<div dangerouslySetInnerHTML={{ __html: text }}/>}
        testId="Campaign-Banner"
        text={campaign.campaignTitleShort}
        isDeadline
      />

  ) : null;
}
