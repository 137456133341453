import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import navigationService from '../../services/navigation.service';

import ChecklistSvg from '../../svgs/checklist.svg';

import StepOneSvg from '../../svgs/step_one.svg';
import StepOneFilledSvg from '../../svgs/step_one_filled.svg';

import StepTwoSvg from '../../svgs/step_two.svg';
import StepTwoFilledSvg from '../../svgs/step_two_filled.svg';

import StepThreeSvg from '../../svgs/step_three.svg';
import StepThreeFilledSvg from '../../svgs/step_three_filled.svg';

type ActiveStep = 1|2|3;

type Props = {
  activeStep: ActiveStep;
}
@observer export default class OnboardingProgressBar extends React.Component<Props> {

  renderNumberOne(activeStep: number) {
    const isActive = activeStep === 1;
    const isCompleted = activeStep > 1;

    const stepClassName = classnames('OnboardingProgressBar-step', {
      'OnboardingProgressBar-step--is-active': isActive,
      'OnboardingProgressBar-step--is-completed': isCompleted,
    });

    const stepSeparatorClassName = classnames('OnboardingProgressBar-stepSeparator', {
      'OnboardingProgressBar-stepSeparator--is-completed': isCompleted,
    });

    return (
      <React.Fragment>
        <div className={stepClassName} data-test-id="step1">
          {isActive && <StepOneFilledSvg/>}
          {!isActive && <StepOneSvg onClick={() =>
            isCompleted && navigationService.navigate(navigationService.getOnboardingLink())}/>}
          <div className="OnboardingProgressBar-stepLabel--inputs">
            Angaben
          </div>
        </div>
        <div className={stepSeparatorClassName}/>
      </React.Fragment>
    );
  }

  renderNumberTwo(activeStep: number) {
    const isActive = activeStep === 2;
    const isCompleted = activeStep > 2;

    const stepClassName = classnames('OnboardingProgressBar-step', {
      'OnboardingProgressBar-step--is-active': isActive,
      'OnboardingProgressBar-step--is-completed': isCompleted,
    });

    const stepSeparatorClassName = classnames('OnboardingProgressBar-stepSeparator', {
      'OnboardingProgressBar-stepSeparator--is-completed': isCompleted,
    });

    return (
      <React.Fragment>
        <div className={stepClassName} data-test-id="step2">
          {isActive && <StepTwoFilledSvg/>}
          {!isActive && <StepTwoSvg onClick={() =>
            isCompleted && navigationService.navigate(navigationService.getOnboardingCoverageLink())}/>}
          <div className="OnboardingProgressBar-stepLabel--features">
            Absicherung
          </div>
        </div>

        <div className={stepSeparatorClassName}/>
      </React.Fragment>
    );
  }

  renderNumberThree(activeStep: number) {
    const isActive = activeStep === 3;

    const stepClassName = classnames('OnboardingProgressBar-step', {
      'OnboardingProgressBar-step--is-active': isActive
    });

    return (
      <React.Fragment>
        <div className={stepClassName} data-test-id="step3">
          {isActive && <StepThreeFilledSvg/>}
          {!isActive && <StepThreeSvg/>}
          <div className="OnboardingProgressBar-stepLabel--reductions">
            Rabatte
          </div>
        </div>

        <div className="OnboardingProgressBar-stepSeparator"/>
      </React.Fragment>
    );
  }

  renderResult() {
    return (
      <div className="OnboardingProgressBar-step">
        <ChecklistSvg />
        <div className="OnboardingProgressBar-stepLabel--comparison">
          Vergleich
        </div>
      </div>
    );
  }

  render() {
    const activeStep = this.props.activeStep;

    return (
      <div className="OnboardingProgressBar">
        {this.renderNumberOne(activeStep)}
        {this.renderNumberTwo(activeStep)}
        {this.renderNumberThree(activeStep)}
        {this.renderResult()}
      </div>
    );
  }
}
