import http from 'bu.http';
import configService from './../services/config.service';
import { Campaign } from '../types/campaign';

class CampaignService {
  mobileApi: string;

  constructor() {
    this.mobileApi = configService.getConfig().services.mobileApi;
  }

  async getActiveCampaign(): Promise<Campaign|undefined> {
    return (await http.get<Campaign|''>(this.mobileApi, 'campaign/active')) || undefined;
  }
}

export default new CampaignService();
